import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import SwfApiService from "@/core/services/api.service.swf";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import { mapGetters } from "vuex";
import ProductCard from "@/view/content/widgets/product-card/product-card";

export default {
  name: "swf-offer",
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.swf");
    },
    yesNoAllList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    memberColor() {
      return function(item) {
        var epochEnd = Math.floor(Date.parse(item.endDate) / 1000);
        var epochStart = Math.floor(Date.parse(item.startDate) / 1000);
        var epochNow = Math.floor(new Date().getTime() / 1000);
        if (item.membersCount === item.maxNumberOfMembers) {
          return "green";
        } else if (epochStart > epochNow) {
          return "blue lighten-3";
        } else if (epochNow > epochEnd) {
          if (item.adminApproved && item.shopApproved) return "red";
          else return "gray darken-4";
        } else {
          return "yellow darken-1";
        }
      };
    },
    memberIcon() {
      return function(item) {
        var epochEnd = Math.floor(Date.parse(item.endDate) / 1000);
        var epochStart = Math.floor(Date.parse(item.startDate) / 1000);
        var epochNow = Math.floor(new Date().getTime() / 1000);
        if (item.membersCount === item.maxNumberOfMembers) {
          return "mdi-check";
        } else if (epochStart > epochNow) {
          return "mdi-pause";
        } else if (epochNow > epochEnd) {
          return "mdi-stop";
        } else if (item.membersCount === 0) {
          return "mdi-step-forward";
        } else {
          return "mdi-play";
        }
      };
    },
    headers() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
          width: "2%"
        },
        { text: this.$i18n.t("common.city"), value: "city", sortable: false },
        {
          text: this.$i18n.t("common.startDate"),
          value: "startDate",
          sortable: false,
          align: "center"
        },
        {
          text: this.$i18n.t("common.endDate"),
          value: "endDate",
          sortable: false,
          align: "center"
        },
        {
          text: this.$i18n.t("common.shop"),
          value: "shop",
          sortable: false,
          align: "center"
        },
        {
          text: this.$i18n.t("common.items"),
          value: "shoppingItems",
          sortable: false,
          width: "2%",
          align: "center"
        },
        {
          text: this.$i18n.t("common.orderOptions"),
          value: "orderOptions",
          sortable: false,
          align: "center"
        },
        {
          text: this.$i18n.t("common.members"),
          value: "members",
          sortable: false,
          width: "7%",
          align: "center"
        },
        {
          text: this.$i18n.t("common.shopDiscount"),
          value: "discountPercentage",
          sortable: false,
          width: "2%",
          align: "center"
        },
        {
          text: this.$i18n.t("common.oobleeDiscount"),
          value: "oobleeDiscountPercentage",
          sortable: false,
          width: "2%",
          align: "center"
        },
        {
          text: this.$i18n.t("commonTable.adminStatus"),
          value: "adminStatus",
          sortable: false,
          width: "2%",
          align: "center"
        },
        {
          text: this.$i18n.t("commonTable.shopStatus"),
          value: "shopStatus",
          sortable: false,
          width: "2%",
          align: "center"
        },

        { text: "", value: "actions", sortable: false }
      ];
    },
    shoppingItemsHeaders() {
      return [
        { text: this.$i18n.t("common.shop"), value: "shop", sortable: false },
        { text: this.$i18n.t("common.item"), value: "item", sortable: false },
        {
          text: this.$i18n.t("common.shopDiscount"),
          value: "shopDiscount",
          sortable: false,
          align: "center"
        },
        {
          text: this.$i18n.t("common.oobleeDiscount"),
          value: "oobleeDiscount",
          sortable: false,
          align: "center"
        },
        {
          text: this.$i18n.t("common.totalDiscount"),
          value: "totalDiscount",
          sortable: false,
          align: "center"
        },
        {
          text: this.$i18n.t("commonTable.quantity"),
          value: "quantity",
          sortable: false,
          align: "center"
        },
        {
          text: this.$i18n.t("common.voucher"),
          value: "voucher",
          sortable: false,
          align: "center"
        }
      ];
    }
  },
  data() {
    return {
      antedateDialog: false,
      antedateDialogTitle: "Select a date and time",
      antedateValue: new Date(),
      antedateItem: null,
      datePickerOptions: {
        type: "datetime",
        format: "YYYY-MM-DD HH:mm:ss",
        scrollable: false,
        readonly: true,
        landscape: true
      },

      dialogApprove: false,
      validApprove: false,
      swfOfferForEdit: null,
      additionalVoucherAmount: 0,
      dialogReject: false,
      swfApiInitiated: false,
      approveDialogType: "approve",
      used: null,
      claimed: null,
      userName: null,
      shopName: null,
      totalSwfRequests: 0,
      swfRequests: [],
      loading: true,
      pagination: {},
      errors: []
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.swfApiInitiated) this.retrieveSwfRequests();
      },
      deep: true
    }
  },
  async created() {
    this.loading = true;
    this.$log.debug("await init swf service - start");
    await SwfApiService.init();
    this.$log.debug("await init swf service - end");
    this.swfApiInitiated = true;
    this.retrieveSwfRequests();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
  },
  components: {
    ConfirmDialog,
    ProductCard
  },
  methods: {
    showAntedateDialog(item) {
      this.antedateValue = new Date();
      console.log("antedate dialog started for " + item.id);
      this.antedateItem = item;
      this.antedateDialog = true;
    },
    cancelAntedate() {
      console.log("antedate dialog cancled");
      this.antedateDialog = false;
    },
    acceptAntedate() {
      //console.log('Accepted datetime change for: ' + item.id + ", to value " + this.antedateValue);
      console.log(
        "Accepted datetime change for: " + this.antedateValue + ", swf: " + this.antedateItem.id
      );
      this.antedate(this.antedateItem.id, this.antedateValue);
      this.antedateDialog = false;
    },
    antedate(swfId, epoch) {
      console.log("change date start; " + epoch + " for swfId " + swfId + typeof epoch);
      this.loading = true;
      if (swfId != null) {
        let antedateRequest = {};
        antedateRequest["datetime"] = Math.floor(epoch.getTime() / 1000);
        antedateRequest["id"] = swfId;
        SwfApiService.post("/admin-panel/change-date/" + swfId, antedateRequest)
          .then(response => {
            this.$log.debug("Antedated swf: ", swfId);
            this.$log.debug("Response: ", response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.retrieveSwfRequests();
            this.antedateDialog = false;
            this.loading = false;
          });
      }
    },
    swfAcceptAndStart(additionalVoucherAmount, item) {
      if (!item.id) return;
      const body = {
        shoppingWithFriendsId: item.id,
        user: this.currentUser.username,
        additionalVoucherAmount: additionalVoucherAmount,
        shopId: 0
      };

      this.loading = true;
      SwfApiService.post("/admin-panel/accept-and-start", body)
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
          this.$bvToast.toast("Error: " + error, {
            solid: true,
            variant: "error",
            noCloseButton: false
          });
        })
        .then(response => {
          this.$log.debug("Approved swf: ", item.id);
          this.$log.debug("Response: ", response);
        })
        .finally(() => {
          this.retrieveSwfRequests();
          this.dialogApprove = false;
          this.loading = false;
        });
    },
    getPercentage(price, percent) {
      if (!price) return;
      const value = (percent / 100) * price;
      return value.toFixed(2);
    },
    validateAndApprove(additionalVoucherAmount, item) {
      const today = moment();
      const datesValid = moment(item.startDate).isBefore(today);
      const isValid = this.$refs.form?.validate();

      if (!isValid) return;

      if (this.approveDialogType === "acceptAndStart" && !datesValid) {
        const translate = this.$i18n.t("common.startDateFuture");
        this.$bvToast.toast("Error: " + translate, {
          solid: true,
          variant: "warning",
          autoHideDelay: 1000
        });

        return;
      }

      if (this.approveDialogType === "approve") {
        this.approve(additionalVoucherAmount, item);
      }

      if (this.approveDialogType === "acceptAndStart") {
        this.swfAcceptAndStart(additionalVoucherAmount, item);
      }
    },
    showApproveDialog(item, type) {
      this.swfOfferForEdit = item;
      this.additionalVoucherAmount = 0;
      this.dialogApprove = true;
      this.approveDialogType = type;
    },
    async approveDialog(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.membership.approveMessage") +
            " Shopping with friends with ID: " +
            item.id +
            " ?"
        )
      ) {
        this.approve(item);
      }
    },
    approve(additionalVoucherAmount, item) {
      if (!item.id) return;
      this.loading = true;
      let approveRequest = {};
      approveRequest["shoppingWithFriendsId"] = item.id;
      approveRequest["user"] = this.currentUser.username;
      approveRequest["additionalVoucherAmount"] = additionalVoucherAmount;
      approveRequest["shopId"] = 0;
      SwfApiService.post(`/admin-panel/accept`, approveRequest)
        .then(response => {
          this.$log.debug("Approved swf: ", item.id);
          this.$log.debug("Response: ", response);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => {
          this.retrieveSwfRequests();
          this.dialogApprove = false;
          this.loading = false;
        });
    },
    async showRejectDialog(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.membership.rejectMessage") +
            " Shopping with friends with ID: " +
            item.id +
            " ?"
        )
      ) {
        this.reject(item);
      }
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to reject SWF with id: " + item.id + " ?"
        )
      ) {
        this.reject(item);
      }
    },
    reject(item) {
      this.loading = true;
      if (item.id != null) {
        let rejectRequest = {};
        rejectRequest["shoppingWithFriendsId"] = item.id;
        rejectRequest["user"] = this.currentUser.username;
        SwfApiService.post(`/admin-panel/reject`, rejectRequest)
          .then(response => {
            this.$log.debug("Rejected swf: ", item.id);
            this.$log.debug("Response: ", response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.retrieveSwfRequests(), (this.loading = false)));
      }
    },
    parseDate(date) {
      if (date == null) return "";
      return moment
        .utc(date)
        .local()
        .format("yyyy-MMM-DD HH:mm");
    },
    getShopNameById(id, shops = []) {
      return shops.find(item => item.id == id)?.name || "";
    },
    getShopNames(shops = []) {
      return shops.map(item => item.name).join(", ");
    },
    getRequestParams() {
      let params = {};

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["Sort"] = sort;
      params["PageNumber"] = this.pagination.page - 1;
      params["PageSize"] = this.pagination.itemsPerPage;

      return params;
    },
    retrieveSwfRequests() {
      const params = this.getRequestParams();

      return new Promise(resolve => {
        this.loading = true;
        SwfApiService.query("/admin-requests-enriched", params).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Swf requests: ", response.data.result);
          this.$log.debug("response.headers['x-pagination']: ", response.headers["x-pagination"]);
          const data = response.data.content;

          data.forEach(item => {
            if (item.freeItem) {
              item.freeItem["isFreeItem"] = true;
              item.shoppingItems = [item.freeItem, ...item.shoppingItems];
            }
          });

          this.swfRequests = response.data.content;
          this.totalSwfRequests = response.data.totalNumberOfElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalSwfRequests;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    }
  }
};
